import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Layout',
     component: Layout,
    meta: {
      title: 'layout'
    },

    children: [
      {
        path: '/',
        redirect: '/index'

      },
      {
        path: '/index',
        name: 'Index',
        meta: {
          title: '首页',
          keepAlive: true // 需要缓存
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/index/Index')
      },
      {
        path: '/commoditydetails',
        name: 'Commoditydetails',
        meta: {
          title: '商品详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/commoditydetails/Commoditydetails')
      },
      {
        path: '/cart',
        name: 'Cart',
        meta: {
          title: '购物车'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/cart/Cart')
      },
      {
        path: '/buyfromcart',
        name: 'Buyfromcart',
        meta: {
          title: '购买信息'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/buyfromcart/Buyfromcart')
      },
      {
        path: '/mine',
        name: 'Mine',
        meta: {
          title: '我的主页'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/mine/Mine')
      },
      {
        path: '/vippage',
        name: 'Vippage',
        meta: {
          title: '会员设置'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/vippage/Vippage')
      },
      {
        path: '/myintegral',
        name: 'Myintegral',
        meta: {
          title: '我的积分'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/mine/Myintegral')
      },
      {
        path: '/favorites',
        name: 'Favorites',
        meta: {
          title: '我的积分'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/mine/Favorites')
      },
      {
        path: '/coupon',
        name: 'Coupon',
        meta: {
          title: '优惠券'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/mine/Coupon')
      },
      {
        path: '/addresslist',
        name: 'Addresslist',
        meta: {
          title: '地址管理'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/mine/Addresslist')
      },
      {
        path: '/orderlist',
        name: 'Orderlist',
        meta: {
          title: '我的订单'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/order/Orderlist')
      },
      {
        path: '/orderinfo',
        name: 'Orderinfo',
        meta: {
          title: '订单详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/order/Orderinfo')
      },
      {
        path: '/saledorderinfo',
        name: 'Saledorderinfo',
        meta: {
          title: '售后订单详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/order/Saledorderinfo')
      },
      {
        path: '/applysaledorder',
        name: 'Applysaledorder',
        meta: {
          title: '申请退货'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/order/Applysaledorder')
      },
      {
        path: '/bulletinboard',
        name: 'Bulletinboard',
        meta: {
          title: '公告'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/bulletinboard/Bulletinboard')
      },
      {
        path: '/bulletinboardinfo',
        name: 'Bulletinboardinfo',
        meta: {
          title: '公告详情'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/bulletinboard/Bulletinboardinfo')
      },
      {
        path: '/purchasenotes',
        name: 'Purchasenotes',
        meta: {
          title: '购买须知'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/explain/Purchasenotes')
      },
      {
        path: '/postsalesservice',
        name: 'Postsalesservice',
        meta: {
          title: '售后服务'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/explain/Postsalesservice')
      },
      {
        path: '/complaintservice',
        name: 'Complaintservice',
        meta: {
          title: '投诉说明'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/explain/Complaintservice')
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        meta: {
          title: '关于我们'
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/explain/Aboutus')
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/aliPay',
    name: 'AliPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/buyfromcart/Alipay.vue')
  }

]

const router = new VueRouter({
  routes
})

export default router
