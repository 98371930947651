
<!-- 封装VanFieldSelectPicker组件 -->
<template>
  <div class="dh-field">
    <div class=" van-hairline--bottom">
    <van-field
      v-model="resultLabel"
      v-bind="$attrs"
      readonly
      :is-link="$attrs.disabled === undefined || $attrs.disabled === false"
      input-align="right"
      @click="showPopu($attrs.disabled)"
      error-message-align='right'
      class="dh-cell"
    />
    <van-popup v-model="show" position="bottom">
      <van-field v-model="searchVal" class="search"  :placeholder="searchPh" @input="search" v-if="isSearch" input-align="left"/>
      <van-picker
        v-bind="$attrs"
        :columns="columnsData"
        :default-index="default_index"
        show-toolbar
        @cancel="cancel"
        @confirm="onConfirm"
        @change="change"
        :value-key="this.option.label"
      />
    </van-popup>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VanFieldSelectPicker',
  model: {
    prop: 'selectValue'
  },
  props: {
    columns: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectValue: {
      type: [String, Number],
      default: ''
    },
    option: {//这里一定要注意，我们项目的字典固定是name，code如果你的项目不是，要么改这里默认值，要么调用的时候要自定
      type: Object,
      default: function () {
        return { label: 'name', value: 'code' }
      }
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    diy: { // 进针对特殊场景，自定义数据
      type: Boolean,
      default: false
    },
    offOption: { // 关闭option配置key-value;当数据是非集合的数组的时候，开启
      type: Boolean,
      default: false
    },
    searchPh: {
      type: String,
      default: '输入搜索信息'
    },
  },
  computed: {
    resultLabel: {
      get () {
        const res = this.columns.filter(item => {
          const data = this.offOption ? item : item[this.option.value]
          return data === this.resultValue
        })
        let label = ''
        if (res.length) {
          label = this.offOption ? res[0] : res[0][this.option.label]
        }
        if (this.diy && !res.length && this.selectValue) {
          this.setSearch()
        }
        return label || this.selectValue
      },
      set () {

      }
    }
  },
  created () {
    sessionStorage.removeItem("houseData");
  },
  data () {
    return {
      show: false,
      searchVal: '',
      resultValue: this.selectValue,
      columnsData: [],
      default_index: 0 // 默认选中项索引
    }
  },
  methods: {
    setSearch () {
      this.searchVal = this.selectValue
    },
    search (val) {
      if (val) {
        this.columnsData = this.columns.filter(item => {
          const data = this.offOption ? item : item[this.option.label]

          return data.indexOf(val) > -1
        })
      } else {
        this.columnsData = JSON.parse(JSON.stringify(this.columns))
      }
    },
    onConfirm (value, index) {
      let data = ''
      if (value) {
        data = this.offOption ? value : value[this.option.value]
      }

      if (this.diy) {
        this.resultValue = data || this.searchVal
      } else {
        this.resultValue = data
      }
      this.show = !this.show
      this.$emit('confirm', value, index, this.resultValue)
    },
    change (val, index) {
      let data = ''
      if (val) {
        data = this.offOption ? val : val[this.option.value]
      }
      this.$emit('change', val, index,data)
    },
    cancel () {
      this.show = !this.show
      this.$emit('cancel')
    },
    showPopu (disabled) {
      this.columnsData = JSON.parse(JSON.stringify(this.columns))
      this.resultValue = this.selectValue
      if (disabled !== undefined && disabled !== false) {
        return false
      } else {
        this.show = !this.show
      }
    }
  },
  watch: {
    selectValue: function (newVal) {
      this.resultValue = newVal
    },
    resultValue (val) {
      this.searchVal = ''
      this.columnsData = JSON.parse(JSON.stringify(this.columns))
      this.$emit('input', val)
    },
    show (val) {
      if (val) {
        if (this.diy && this.resultLabel && this.searchVal) {
          this.search(this.searchVal)
        }
      }
    },
    resultLabel: { // 显示默认选中
      handler (val) {
        if (val) {
          if (this.offOption) {
            this.default_index = this.columns.indexOf(val)
          } else {
            const data = this.columns.map(item => item[this.option.label])
            this.default_index = data.indexOf(val)
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
  .dh-field {
    padding: 0 16px;background:#fff;
    .dh-cell.van-cell{padding: 10px 0;}
    .dh-cell.van-cell--required::before{left: -8px;}
    .van-popup{border-radius: 20px 20px 0 0;}
    .search{
      /deep/.van-field__value{background: #f7f7f7;padding: 2px;}
    }
  }
</style>



