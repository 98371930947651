<template>
  <div>
    <van-overlay :show="showLoginDia">
      <div class="zhanweilogindia"></div>
      <div class="loginboxbg">
        <div class="mainbox">
          <div>
            <img
              @click="closeLoginDia"
              class="closeicon"
              src="../assets/imgs/clock@3x.png"
              alt=""
            />
          </div>
          <div class="logintitle">欢迎登录</div>
          <div class="loginformbox">
            <input
              class="logininput"
              type="number"
              v-model="loginData.telephone"
              placeholder="请输入手机号"
            />
            <img class="iphoneicon" src="../assets/imgs/iphone@3x.png" alt="" />
          </div>
          <div class="loginformbox2">
            <input
              type="number"
              class="logininput2"
              v-model="loginData.code"
              placeholder="请输入短信验证码"
            />
            <div class="codebtn" @click.stop="getCodeFn">{{ codeText }}</div>
          </div>
          <div class="margintop30">
            <van-checkbox class="vancheckbox" v-model="checked" shape="square"
              >保持登录</van-checkbox
            >
          </div>
          <div>
            <van-button class="loginbtn" @click="loginFn">{{
              btnText
            }}</van-button>
          </div>
          <div class="margintop200">
            <van-divider>or</van-divider>
          </div>
          <!-- <div class="margintop70">
            <div class="flexbox">
              <div>
                <img
                  class="loginicon"
                  src="../assets/imgs/weixin@3x.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="loginicon"
                  src="../assets/imgs/zhifubao@3x.png"
                  alt=""
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getCode_api, telLogin_api } from "../apis/login";
import {userInfo_api} from '../apis/mine'
import local from "@/utils/local.js";
import session from "@/utils/session.js";
export default {
  data() {
    return {
      loginData: {
        telephone: "",
        code: "",
      },
      showLoginDia: false,
      btnText: "登录",
      timer: "",
      isSend: false,
      codeText: "请输入短信验证码",
      totalTime: 60,
      checked: true,
    };
  },
  methods: {
    open(type) {
      if (type == "login") {
        this.btnText = "登录";
      }else if(type=='jion'){
        this.btnText = "加入我们";
      }
      this.showLoginDia = true;
    },
    getCodeFn() {
      if (this.isSend) return;
      this.getCode(); // 获取验证码的接口
      this.isSend = true;
      this.codeText = this.totalTime + "s后重新发送";
      this.timer = setInterval(() => {
        this.totalTime--;
        this.codeText = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.codeText = "重新发送验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    async getCode() {
      let { code, data } = await getCode_api(this.loginData.telephone);
      if (code == 0) {
        this.$toast("验证码已发送");
      }
    },
    async loginFn() {
      if (!this.loginData.telephone || !this.loginData.code) {
        this.$toast("请输入正确的手机号和验证码");
      } else {
        let { code, data } = await telLogin_api(this.loginData);
        if (code == 0) {
          this.$toast.success("登录成功");
          this.showLoginDia = false;
          this.getUserInfo()
          if (this.checked) {
            console.log("保持登录", data);
            local.set("tk", data.userToken);
          } else {
            if (local.get("tk")) {
              local.remove("tk");
            }
            session.set("tk", data.userToken);
          }
          this.$parent.getUserLogin();
        }
      }
    },
    closeLoginDia() {
      this.showLoginDia = false;
    },
   async getUserInfo(){
     let {code,data}=await userInfo_api()
     if (code == 0) {
       this.$bus.$emit('userInfo',data)
       local.set("userInfo",JSON.stringify(data));
       window.qimoClientId.userId = data.id;
        if (data.nickName) {
          window.qimoClientId.nickName = data.nickName;
        } else {
          window.qimoClientId.nickName = data.telephone;
        }

      }

    }
  },
};
</script>

<style lang="less" scoped>
.zhanweilogindia {
  width: 100%;
  height: 80px;
}
.loginboxbg {
  height: calc(100vh - 80px);
  width: 100%;
  background-color: #fff;
}
.mainbox {
  margin: 0 auto;
  width: 690px;
  padding-top: 30px;
}
.closeicon {
  width: 38px;
  height: 38px;
}
.logintitle {
  margin-top: 60px;
  font-size: 36px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}
.logininput {
  border: none;
  width: 623px;
  height: 80px;
  background: #f4f4f4;
  line-height: 80px;
  padding-left: 67px;
}
.loginformbox {
  margin-top: 80px;
  position: relative;
}
.iphoneicon {
  width: 20px;
  height: 30px;
  position: absolute;
  top: 26px;
  left: 25px;
}
.loginformbox2 {
  margin-top: 30px;
  position: relative;
}
.logininput2 {
  border: none;
  width: 666px;
  height: 80px;
  background: #f4f4f4;
  line-height: 80px;
  padding-left: 24px;
}
.codebtn {
  width: 196px;
  height: 80px;
  line-height: 80px;
  padding-left: 4px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #986665;
  position: absolute;
  right: 0px;
  top: 0px;
}
.margintop30 {
  margin-top: 30px;
}
.vancheckbox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.loginbtn {
  margin-top: 30px;
  width: 690px;
  height: 80px;
  background: #986665;
  color: #fff;
}
.margintop200 {
  margin-top: 200px;
}
.margintop70 {
  margin: 0 auto;
  margin-top: 70px;
  width: 350px;
}
.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginicon {
  width: 64px;
  height: 64px;
}
</style>