import req from "../utils/req"

//获取公告栏列表
export function bulletinBoardList_api(params) {
    return req.get(`/app/otaHotel/notice/list`,params )
}
//获取公告详情
export function bulletinBoardInfo_api(params) {
    return req.get(`/app/otaHotel/notice/getInfo`,params )
}

//获取首页公告
export function getNoticeList_api(params) {
    return req.get(`/app/otaHotel/notice/getNoticeList`,params )
}