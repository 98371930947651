import req from "../utils/req"

//获取用户详情
export function userInfo_api(params) {
    return req.get(`/app/otaHotel/user/getUserInfo`, params)
}

//获取积分详情列表
export function integralList_api(params) {
    return req.get(`/app/otaHotel/user/list`, params)
}
//修改用户资料
export function upUserInfo_api(params) {
    return req.post(`/app/otaHotel/user/upUserInfo`, params)
}

//优惠券列表
export function couponList_api(params) {
    return req.get(`/app/otaHotel/user/couponlist`, params)
}

//地址列表
export function addressList_api(params) {
    return req.get(`/app/otaHotel/user-address/list`, params)
}
//删除地址
export function delAddress_api(params) {
    return req.get(`/app/otaHotel/user-address/delete`, params)
}
//新增修改地址
export function addressSave_api(params) {
    return req.post(`/app/otaHotel/user-address/saveOrUpdate`, params)
}