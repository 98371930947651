import req from "../utils/req"

//获取验证码

export function getCode_api(params) {
    return req.get(`/app/otaHotel/user/getCode/${params}` )
}
//验证码登录
export function telLogin_api(params) {
    return req.post(`/app/otaHotel/user/login`,params )
}