<template>
  <div>
    <van-dialog v-model="showCouponPop" confirmButtonText="确定" @confirm="confirmFn" @cancel="cancelFn"
      close-on-click-overlay>
      <div class="couponbox">
        <div class="content">
          <div class="mainitembox" v-for="item in cangetCouponList" :key="item.id">
            <div class="canuseleftbox">
              <div class="padtop50" v-if="item.type == 'CASH'">
                <span class="canlefticon">￥</span>
                <span class="canleftnum">{{ item.benefitCash }}</span>
              </div>
              <div class="padtop50" v-if="item.type == 'DISCOUNT'">
                <span class="canleftnumt">{{ item.benefitDiscount * 10 }}折券</span>
              </div>
              <div class="canleftlable" v-if="item.conditionAmount > 0">
                满{{ item.conditionAmount }}元使用
              </div>
              <div class="canleftlable" v-else>无门槛优惠券</div>
            </div>
            <div class="canrightbox">
              <div class="canrightinfo">
                <div class="canrightvale">
                  <span v-if="item.type == 'CASH'">【现金券】</span>
                  <span v-if="item.type == 'DISCOUNT'">【折扣券】</span>{{ item.name | formartName }}
                </div>
                <div class="canrightlable">
                  {{ item.memo }} 有效期
                  <span v-if="item.startDate">
                    {{ item.startDate | formartDate }}至
                    {{ item.endDate | formartDate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { cangetCoupon_api, getCoupon_api, birthdayCoupon_api, updateBirthdayCoupon_api } from "../apis/layout";
export default {
  data() {
    return {
      cangetCouponList: [],
      showCouponPop: false,
      isBirthday: false,

    };
  },
  filters: {
    formartDate(val) {
      if (val) {
        let newVal = val.substring(0, 10);
        return newVal;
      }
    },
    formartName(val) {
      // if(val.length>10){

      // }
      return val;
    },
  },
  methods: {
    async open() {
      let { code, data } = await cangetCoupon_api();
      if (code == 0) {
        this.cangetCouponList = data;
        if (this.cangetCouponList.length > 0) {
          this.showCouponPop = true;
          this.isBirthday=false
          this.getCoupon()
        } else {
          this.getBirthdayCoupon()
        }
      } else if (code == 1) {
      }
    },
    cancelFn() {
      this.showCouponPop = false;
    },
    async confirmFn() {
      // let idsList = [];
      // this.cangetCouponList.forEach((item) => {
      //   idsList.push(item.id);
      // });
      // let ids = idsList.join(",");
      // let { code, data } = await getCoupon_api({
      //   ids: ids,
      // });
      // if (code == 0) {
      //   // this.$dialog.alert({
      //   //   message: "请到“我的主页”查看优惠券",
      //   // });
      // }
      if(this.isBirthday){
         let idsList = [];
      this.cangetCouponList.forEach((item) => {
        idsList.push(item.id);
      });
      let ids = idsList.join(",");
      let { code, data } = await updateBirthdayCoupon_api({
        ids: ids,
      });
      if (code == 0) {
        this.$dialog.alert({
          message: "请到“我的主页”查看生日券",
        });
      }

      }else{
        this.$dialog.alert({
        message: "请到“我的主页”查看优惠券",
      });
      }
     
    },
    async getCoupon() {
      let idsList = [];
      this.cangetCouponList.forEach((item) => {
        idsList.push(item.id);
      });
      let ids = idsList.join(",");
      let { code, data } = await getCoupon_api({
        ids: ids,
      });
      if (code == 0) {

      }

    },
    async getBirthdayCoupon() {
      let { code, data } = await birthdayCoupon_api()
      if (code == 0) {
        this.cangetCouponList = data;
        if (this.cangetCouponList.length > 0) {
          this.showCouponPop = true;
          this.isBirthday = true;

        } 

      }
    }
  },
};
</script>

<style lang="less" scoped>
.couponbox {
  background: #eeeee2;
  zoom: 0.8;
  padding: 10px;

  .content {
    width: 690px;
    margin: 0 auto;
  }

  .mainitembox {
    margin-top: 20px;
    border-radius: 15px;
    background: #fff;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
  }

  .canuseleftbox {
    display: inline-block;
    vertical-align: top;
    width: 188px;
    height: 188px;
    background: #986665;
    border-radius: 15px 0px 0px 15px;
  }

  .padtop50 {
    padding-top: 30px;
    text-align: center;
  }

  .canlefticon {
    // margin-left: 34px;
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
  }

  .canleftnum {
    font-size: 60px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }

  .canleftnumt {
    font-size: 48px;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
  }

  .canleftlable {
    text-align: center;
    font-size: 24px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
  }

  .canrightbox {
    width: 502px;
    // width: 402px;
    height: 188px;
    background: #ffffff;
    border-radius: 0px 15px 15px 0px;
    display: inline-block;
    vertical-align: top;
  }

  .wid717 {
    width: 717px;
  }

  .canrightinfo {
    margin-left: 30px;
    width: 470px;
    height: 188px;
    // background: #4d36ff;
    display: inline-block;
    vertical-align: top;
  }

  .canrightvale {
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #333333;
    padding-top: 30px;
    margin-bottom: 20px;
  }

  .canrightlable {
    font-size: 20px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
  }

  .canrightbtn {
    width: 112px;
    height: 44px;
    background: #986665;
    border-radius: 22px;
    display: inline-block;

    line-height: 44px;
    text-align: center;
    font-size: 20px;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
    position: relative;
    top: 72px;
  }

  .nocanrightbtn {
    text-align: center;
  }

  .canuserightbox {
    display: inline-block;
    vertical-align: top;
    width: 188px;
    height: 188px;
    background: #e4e4e4;
    border-radius: 15px 0px 0px 15px;
  }

  .canrighticon {
    margin-left: 34px;
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #c9c9c9;
  }

  .canleftnumtwo {
    font-size: 60px;
    font-family: PingFang;
    font-weight: bold;
    color: #c9c9c9;
  }

  .canleftnumtwot {
    font-size: 48px;
    font-family: PingFang;
    font-weight: bold;
    color: #c9c9c9;
  }
}
</style>